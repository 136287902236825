import { useEffect, useLayoutEffect, useRef } from 'react';
function useInterval(callback, delay) {
    var savedCallback = useRef(callback);
    useLayoutEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        if (!delay && delay !== 0) {
            return;
        }
        var id = setInterval(function () { return savedCallback.current(); }, delay);
        return function () { return clearInterval(id); };
    }, [delay]);
}
export default useInterval;
