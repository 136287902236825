import { useLayoutEffect, useState } from 'react';
import { useEventListener } from '../useEventListener';
function useWindowSize() {
    var _a = useState({
        width: 0,
        height: 0,
    }), windowSize = _a[0], setWindowSize = _a[1];
    var handleSize = function () {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useEventListener('resize', handleSize);
    useLayoutEffect(function () {
        handleSize();
    }, []);
    return windowSize;
}
export default useWindowSize;
