import './App.css';
import Web3Modal from "web3modal";
import { providerOptions } from './connectors'
import { providers } from 'ethers';
import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal';
import axios from 'axios';
import { useInterval } from 'usehooks-ts'
import { ethers } from 'ethers'
import CONTRACT from './contracts/PupFrens.json'
import PUP_CONTRACT from './contracts/Puppy.json'

import config from './config.json'

const TESTNET = false
const NETWORK = TESTNET ? "rinkeby" : undefined

const TESTNET_CONTRACT = config.contracts.rinkeby
const MAINNET_CONTRACT = config.contracts.mainnet
const CONTRACT_ADDRESS = TESTNET ? TESTNET_CONTRACT : MAINNET_CONTRACT

const TESTNET_PUP_CONTRACT = "0x183b665119f1289dfd446a2eba29f858ee0d3224"
const MAINNET_PUP_CONTRACT = "0x2696fc1896f2d5f3deaa2d91338b1d2e5f4e1d44"
const PUP_CONTRACT_ADDRESS = TESTNET ? TESTNET_PUP_CONTRACT : MAINNET_PUP_CONTRACT

const API_URL = `${config.contractStateApiBase}${TESTNET ? '?testnet=1' : ''}`

const LOGO_IMG_SRC = config.logoImage
const HOME_URL = config.homeUrl

// TODO: public price.
const MAX_TOKENS = config.maxSupply
const OPENSEA_URL = config.openseaUrl

const REFRESH_STATE_MS = 15000
const WEI_PER_ETH = '1000000000000000000'
const ETHERSCAN_LINK = `https://${TESTNET ? 'rinkeby.' : ''}etherscan.io/address/${CONTRACT_ADDRESS}#code`
const MAX_MINT_PER_TX = 20

const DEFAULT_IMG_INTERVAL = 750

const exclamations = [
  'Love this project!',
  'Much wow!',
  'To the moon!',
  'Lets go!'
]
const TWEET_TEXT = `Just minted my @PupFrens
${exclamations[Math.floor(Math.random(exclamations.length))]}`

const web3Modal = new Web3Modal({
  network: NETWORK, // optional
  cacheProvider: true, // optional
  providerOptions // required
});

let provider, myAddress

function ellipseAddress(address = '', width = 7) {
  if (!address) {
    return '';
  }
  return `${address.slice(0, width + 1)}...${address.slice(-width)}`;
}

function App() {
  const [walletConnected, setWalletConnected] = useState(false)
  const [address, setAddress] = useState(null)
  const [myWeb3Provider, setMyWeb3Provider] = useState(null)
  const [mintNum, setMintNum] = useState(1)
  const [numMinted, setNumMinted] = useState(-1)
  const [showMintedModal, setShowMintedModal] = useState(false)
  const [mintTxHash, setMintTxHash] = useState('')
  const [priceMilliPup, setPriceMilliPup] = useState(200000000)
  const [priceEthWei, setPriceEthWei] = useState(20000000000000000)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [milliPupBalance, setMilliPupBalance] = useState(0)
  const [showBuyPupModal, setShowBuyPupModal] = useState(false)
  const [showPostApprovalModal, setShowPostApprovalModal] = useState(false)
  const [approvalTxid, setApprovalTxid] = useState('')
  const [showMintModal, setShowMintModal] = useState(false)
  const [showMintChoiceModal, setShowMintChoiceModal] = useState(false)

  const [mintImgNum, setMintImgNum] = useState(0)

  useInterval(() => {
    setMintImgNum((mintImgNum + 1) % config.mintImages.length)
  }, DEFAULT_IMG_INTERVAL)

  const handleCloseMintedModal = () => setShowMintedModal(false);
  const handleShowMintedModal = (txHash) => {
    setMintTxHash(txHash)
    setShowMintedModal(true)
  }
  let contract, pupContract
  if (myWeb3Provider) {
    try {
      contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT.abi,
        myWeb3Provider.getSigner(),
      )
      pupContract = new ethers.Contract(
        PUP_CONTRACT_ADDRESS,
        PUP_CONTRACT.abi,
        myWeb3Provider.getSigner(),
      )
    } catch (err) {
      console.log(err);
      console.error('Error initializing contract');
    }
  }

  async function fetchContractData(currentNumMinted) {
    try {
      const response = await axios.get(API_URL)
      console.dir(response.data)
      setNumMinted(Math.max(currentNumMinted, response.data.numMinted))
      setPriceMilliPup(response.data.priceMilliPup)
      setPriceEthWei(response.data.priceEthWei)
      // TODO: set price millipup
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchPupBalance() {
    if (address) {
      try {
        console.log(`balance of ${address}`)
        const response = await pupContract.balanceOf(address)
        console.log('balance data')
        console.log(response)
        setMilliPupBalance(response.toNumber())
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function fetchIsApproved(num = 1) {
    if (address) {
      try {
        const response = await pupContract.allowance(address, CONTRACT_ADDRESS)
        console.log('approval data')
        console.log(response.toNumber())
        console.log(num)
        return response.toNumber() > priceMilliPup * num
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }

  useEffect(() => {
    fetchContractData(numMinted)
  }, [numMinted])

  useEffect(() => {
    fetchPupBalance()
  }, [address])

  useInterval(() => {
    fetchContractData(numMinted)
  }, REFRESH_STATE_MS)

  function initProvider() {
    function disconnectAll() {
      setWalletConnected(false)
      setAddress(null)
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      console.log(accounts);
      if (accounts.length === 0) {
        disconnectAll()
      } else {
        console.log(`setting address in accountsChanged: ${provider.selectedAddress}`)
        setAddress(provider.selectedAddress)
      }
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log(info);
      console.log(`setting address in connect: ${provider.selectedAddress}`)
      setAddress(provider.selectedAddress)
      setWalletConnected(true)
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log(error);
      disconnectAll()
    });
  }

  async function connectWeb3() {
    provider = await web3Modal.connect()
    const web3Provider = new providers.Web3Provider(provider)
    await web3Provider.provider.enable()
    let mySigner = web3Provider.getSigner()
    setMyWeb3Provider(web3Provider)
    myAddress = await mySigner.getAddress();
    initProvider()
    console.log(`setting address in connectWeb3: ${myAddress}`)
    setAddress(myAddress)
    setWalletConnected(true)
  }

  function clearWeb3Cache() {
    web3Modal.clearCachedProvider()
    setAddress(null)
    setWalletConnected(false)
  }

  async function approvePup() {
    let response
    try {
      response = await pupContract.approve(CONTRACT_ADDRESS, 100000000000000)
    } catch (error) {
      console.log(error)
      return
    }
    setShowApprovalModal(false)
    setApprovalTxid(response.hash)
    setShowPostApprovalModal(true)
  }

  async function premintPup(num) {
    if (milliPupBalance < priceMilliPup * num) {
      setShowBuyPupModal(true)
      return
    }
    const approved = await fetchIsApproved(num)
    console.log(`approved: ${approved}`)
    if (!approved) {
      setShowApprovalModal(true)
      return
    }
    setShowMintModal(true)
  }

  async function mint(num) {
    console.log(`minting ${num}`)
    let estimatedGas
    try {
      estimatedGas = await contract.estimateGas.mintWithPup(num);
    } catch (err) {
      console.dir(err)
      alert('You are not able to mint at this time. You may have already minted the max amount, or you don\'t have enough funds in your wallet');
      return;
    }
    console.log(estimatedGas);
    const gasLimit = estimatedGas.add(10000); // Add 10,000 to prevent failures from bad gas limit estimate.
    // eslint-disable-next-line one-var
    let response;
    try {
      response = await contract.mintWithPup(num, { gasLimit });
    } catch (err) {
      console.dir(err)
      alert('There was an issue minting.');
      return;
    }
    console.log(response);
    handleShowMintedModal(response.hash)
  }

  async function mintWithEth(num) {
    console.log(`minting ${num}`)
    const value = ethers.BigNumber.from(priceEthWei).mul(num)
    let estimatedGas
    try {
      estimatedGas = await contract.estimateGas.mintWithEth(num, { value: value.toString() });
    } catch (err) {
      console.dir(err)
      alert('You are not able to mint at this time. You may have already minted the max amount, or you don\'t have enough funds in your wallet');
      return;
    }
    console.log(estimatedGas);
    const gasLimit = estimatedGas.add(10000); // Add 10,000 to prevent failures from bad gas limit estimate.
    // eslint-disable-next-line one-var
    let response;
    try {
      response = await contract.mintWithEth(num, { gasLimit, value: value.toString() });
    } catch (err) {
      console.dir(err)
      alert('There was an issue minting.');
      return;
    }
    console.log(response);
    handleShowMintedModal(response.hash)
  }

  function readablePricePup() {
    const totalMilliPup = priceMilliPup * mintNum
    const pup = totalMilliPup / 1000
    const millionPup = pup / 1000000
    if (millionPup >= 1) {
      return `${millionPup}M $PUP`
    }
    const thousandPup = pup / 1000
    return `${thousandPup}K $PUP`
  }

  function readablePriceEth() {
    return `${priceEthWei * mintNum / WEI_PER_ETH} ETH`
  }

  function MintSection() {
    function handleChangeMintNum(event) {
      if (!event.target.value) {
        setMintNum(null)
      } else {
        setMintNum(Math.max(1, Math.min(event.target.value, maxMint())))
      }
    }
    function maxMint() {
      return MAX_MINT_PER_TX
    }
    return (
      <>
        <div className="mint-option">
          <div className='mint-img-container'>
            <img style={{ border: `2px solid ${config.borderColor}` }} className='mint-img' src={config.mintImages[mintImgNum]} alt={'alt-mint'} />
          </div>
          <div className='mint-name bold'>
            <div className='mint-type bold'>{mintNum} PupFren{mintNum > 1 ? 's' : ''}</div>
            <div className='mint-price bold'>{readablePricePup()} or {readablePriceEth()}</div>
          </div>
          <div className='mint-action'>
            {
              walletConnected ?
                <>
                  <div style={{ background: config.altColor, color: config.textColor2, border: `1px solid ${config.borderColor}` }} className='connect-or-mint bold shadow' onClick={() => setShowMintChoiceModal(true)}>Mint</div>
                </>
                :
                <>
                  <div style={{ background: config.altColor, color: config.textColor2, border: `1px solid ${config.borderColor}` }} className='connect-or-mint bold shadow' onClick={connectWeb3}>Connect Wallet</div>
                </>
            }
            <input className='num-mint' type='number' value={mintNum} min='1' max={maxMint()} onChange={handleChangeMintNum}></input>
          </div>
          <br />
          <p className="small-text bold">
            <a id="phases" href="https://pupfrens.com#phases" target="_blank">See mint phases</a>
          </p>
        </div>
      </>
    )
  }

  function PublicTitleText() {
    if (!walletConnected) {
      return `Connect your wallet above to begin.`
    }
    return `Mint up to 10 pupfrens per transaction below. Hurry before they sell out!`
  }

  function Title() {
    if (numMinted >= MAX_TOKENS) {
      return (
        <>
          <h1 className="bold">Public sale is open!</h1>
          <p className="text">
            {PublicTitleText()}
          </p>
        </>
      )
    }
  }

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#46ef5f',
      borderRadius: '20px',
      border: '2px solid black',
      color: 'black',
      fontWeight:'bold',
      fontFamily: 'Montserrat',
      padding: '50px'
    },
  };

  function etherscanTx(txid) {
    return `https://${TESTNET ? 'rinkeby.' : ''}etherscan.io/tx/${txid}`
  }

  const appStyle = {
    color: config.textColor,
    background: config.background
  }
  if (config.backgroundImage) {
    appStyle.backgroundImage = `url(${config.backgroundImage})`
  }

  const buttonStyle = {
    border: `1px solid ${config.borderColor}`,
    background: config.altColor,
    color: config.textColor2 || config.textColor
  }
  return (
    <div className="App" style={appStyle}>
      <ReactModal
        isOpen={showMintedModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
      >
        <div id="modal-inner">
          <h1>Your mint is pending!</h1>
          <p>Your mint will appear on OpenSea a few minutes after your transaction confirms.</p>
          <p>In the meantime maybe send out a tweet to show some support!</p>
          <br />
          <div id="modal-buttons">
            <div id="view-tx-modal" className="modal-button" onClick={() => { window.open(`https://twitter.com/intent/tweet?text=${encodeURI(TWEET_TEXT)}&hashtags=PupFrens`) }}>Tweet</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { window.open(etherscanTx(mintTxHash)) }}>View Transaction</div>
            <div id="close-modal" className="modal-button" onClick={handleCloseMintedModal}>Close</div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showApprovalModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
      >
        <div id="modal-inner">
          <p>Since you are minting with $PUP, you must first approve the PupFrens contract to burn your $PUP.</p>
          <p>If you've already done this, wait until the previous transaction confirms and refresh the page.</p>
          <br />
          <div id="modal-buttons">
            <div id="view-tx-modal" className="modal-button" onClick={approvePup}>Approve $PUP</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowApprovalModal(false) }}>Cancel</div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showBuyPupModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
      >
        <div id="modal-inner">
          <p>You do not have enough $PUP to mint {mintNum} at the current tier.</p>
          <p>You must buy enough $PUP from the puppycoin website first.</p>
          <p>Your balance: {(milliPupBalance / 1000).toLocaleString("en-US")} $PUP</p>
          <p>Required: {(mintNum * priceMilliPup / 1000).toLocaleString("en-US")} $PUP</p>
          <br />
          <div id="modal-buttons">
            <div id="view-tx-modal" className="modal-button" onClick={() => window.open('https://puppycoin.fun')}>Buy $PUP</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowBuyPupModal(false) }}>Cancel</div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showPostApprovalModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
      >
        <div id="modal-inner">
          <p>Your approval transaction is pending. Once it confirms, refresh the page and you should be able to mint!</p>
          <br />
          <div id="modal-buttons">
            <div id="view-tx-modal" className="modal-button" onClick={() => { window.open(etherscanTx(approvalTxid)) }}>View Transaction</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowPostApprovalModal(false) }}>Cancel</div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showMintModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
      >
        <div id="modal-inner">
          <p>Minting {mintNum} PupFren{mintNum > 1 ? 's' : ''} for a total of {((mintNum * priceMilliPup) / 1000).toLocaleString("en-US")} $PUP</p>
          <br />
          <div id="modal-buttons">
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowMintModal(false); mint(mintNum) }}>Confirm</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowMintModal(false) }}>Cancel</div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showMintChoiceModal}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
      >
        <div id="modal-inner">
          <h1>Use $PUP or $ETH?</h1>
          <p>You are about to mint {mintNum} PupFren{mintNum > 1 ? 's' : ''}.</p> 
          <p>Do you want to mint using $PUP or $ETH?</p>
          <p>Using $PUP is less expensive.</p>
          <br />
          <div id="modal-buttons">
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowMintChoiceModal(false); premintPup(mintNum) }}>Mint for {readablePricePup()}</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowMintChoiceModal(false); mintWithEth(mintNum) }}>Mint for {readablePriceEth()}</div>
            <div id="view-tx-modal" className="modal-button" onClick={() => { setShowMintChoiceModal(false) }}>Cancel</div>
          </div>
        </div>
      </ReactModal>
      <div id="top">
        <div id="nav">
          <div className="nav-item shadow" style={buttonStyle} id="home" onClick={() => window.open(HOME_URL)}>HOME</div>
          <div className="nav-item" id="top-logo" onClick={() => window.open(HOME_URL)}><img src={LOGO_IMG_SRC} id="top-logo-img" alt="top-logo" /></div>
          {walletConnected ?
            <>
              <div style={buttonStyle} className="nav-item shadow" id="connect-wallet" onClick={clearWeb3Cache} >{ellipseAddress(address)}</div>
            </>
            :
            <>
              <div style={buttonStyle} className="nav-item shadow" id="connect-wallet" onClick={connectWeb3}>CONNECT WALLET</div>
            </>
          }
        </div>
        <div id="title">
          {Title()}
          {
            numMinted === -1 ?
              <></>
              :
              <div style={{ border: `2px solid ${config.borderColor}` }} className="text bold shadow" id="minted">
                {(numMinted).toLocaleString("en-US")} {config.singleItemName}s Minted
              </div>
          }
        </div>
      </div>
      <div id="bottom">
        <div id="minting" className='shadow'>
          {MintSection(connectWeb3)}
        </div>
      </div>
      <div className="view-opensea-container">
        <div style={buttonStyle} className='shadow view-opensea-button' onClick={() => window.open(`https://puppycoin.fun`)}>Buy $PUP</div>
      </div>
      <div className="view-opensea-container">
        <div style={buttonStyle} className='shadow view-opensea-button' onClick={() => window.open(OPENSEA_URL)}>View Collection on OpenSea</div>
      </div>
      <div id="contract-section" style={{ borderTop: `1px solid ${config.borderColor}` }}>
        <p>Smart Contract:</p>
        <a style={{ color: config.textColor }} id="contract-link" rel="noreferrer" target="_blank" href={ETHERSCAN_LINK}>{CONTRACT_ADDRESS}</a>
        <br /><br /><br />
      </div>
      <br /><br />
    </div>
  );
}

export default App;
