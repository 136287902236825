import WalletConnectProvider from '@walletconnect/web3-provider';
import WalletLink from 'walletlink';

const INFURA_IDS = [
    '263de7590ed944318bfe063eb6d9669b',
    '40b2f5b5291a4e76bfe11fe3916e015e', // 1
    '412916a649bc4ff3a0b288cb1c7f5a8c', // 2
    '6f3da9c536c0434cb09c85386bde918e', // 3
    '816b1971523741e4bb388ed1bffb5dcc', // 4
    '0e38533aa3cf4726aa507bc3b50677fc', // 5
    'f0e34101a4e848c69ff8e16dfbe058ef', // 6
    '0ce780623178451299588d95ee956a7e', // 7
    '9ebc329595694bcbb448784ed768cddb', // 8
    '56e780b3e05549d888d9c1ddb2347e28', // 9
    'ddf22af00fac44d1866ed88108b14c69', // 10
]

const INFURA_ID = chooseRandom(INFURA_IDS)

function chooseRandom(list) {
    const index = Math.floor(list.length * Math.random())
    return list[index]
}

export const providerOptions = {
    // Example with injected providers
    injected: {
        display: {
            // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
            name: 'BROWSER',
            description: 'CONNECT WITH THE PROVIDER IN YOUR BROWSER',
        },
        package: null,
    },
    // Example with WalletConnect provider
    walletconnect: {
        display: {
            // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
            name: 'MOBILE',
            description: 'MOBILE WALLET CONNECT',
        },
        package: WalletConnectProvider,
        options: {
            infuraId: INFURA_ID,
        }
    },
    'custom-walletlink': {
        display: {
            logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
            name: 'COINBASE',
            description: 'CONNECT TO COINBASE WALLET (NOT COINBASE APP)',
        },
        options: {
            appName: 'Coinbase', // Your app name
            networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
            chainId: 1,
        },
        package: WalletLink,
        connector: async (_, options) => {
            const { appName, networkUrl, chainId } = options;
            const walletLink = new WalletLink({
                appName,
            });
            const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
            await provider.enable();
            return provider;
        },
    },
};